'use strict';

angular.module('client.services').factory('OrgCollectionService', ['$resource', 'api_server',
  function ($resource, api_server) {
    return {
      getCollections: function () {
        return $resource(api_server + '/users/available_collections', {}, {
          getAvailableList: {method: 'GET', isArray: true}
        });
      },
      addCollection: function (uuid) {
        return $resource(api_server + 'users/enable-collection/:uuid', {}, {
          add: {method: 'POST', params: {uuid: uuid}}
        });
      },
      removeCollection: function (uuid) {
        return $resource(api_server + 'users/disable-collection/:uuid', {}, {
          remove: {method: 'POST', params: {uuid: uuid}}
        });
      }
    };
  }]);

