'use strict';

(function () {
  var module = angular.module('client.components');

  controller.$inject = ['$scope', 'loginService', '$location', '$window', '$log', 'api_server'];

  function controller($scope, loginService, $location, $window, $log, api_server) {
    $scope.user = {username: "", password: ""};

    $scope.explorosTitle = "Exploros";
    $scope.explorosImage = "resources/exploros_logo.svg";

    // Error handler object
    $scope.error = {
      nameValid: true,
      loginValid: true
    };

    var errors = {
      signin_no_user: "Sorry, we don't recognize this email address or username.",
      signin_wrong_password: "Sorry, this password is invalid for the specified user.",
      signin: "Sorry, we don't recognize that email address or password.",
      signin_no_account: "Sorry, you'll need to create an account first before logging in.",
      signin_google_denied: "You'll need to authorize Exploros in order to sign in with Google. Choose 'Allow' on the Google authorization screen to proceed.",
      signin_email_google_user: "We found an Exploros account associated with this Google account. Please sign in with Google instead.",
      signin_google_exists: "You have already signed up for Exploros using this Google account.  Login and select Join a class using the provided class code.",
      signup: "There was a problem signing up.",
      bad_domain: "Your email does not match the domain we have on record for your campus. Please signup with your school e-mail address."
    };

    var app_url = encodeURI($location.protocol() + '://' + window.location.host);
    $scope.googleRoute = api_server + '/auth/google/login/?app_url=' + app_url;
    $scope.gg4lRoute = api_server + '/auth/gg4l/login';
    $scope.cleverRoute = api_server + '/auth/clever/login';
    $scope.classlinkRoute = api_server + '/auth/classlink/login';

    // Show error on page load if "error" parameter exists in the query string
    if ($location.search().error) {
      // Show login error if a user gets an error on trying to signup
      if ($location.search().error == 'signin') {
        $scope.error.loginValid = false;
        $scope.error.text = errors.signin;
        $scope.googleRoute += '&prompt=select_account';
      }
      // Show login error if a user tries to sign in without having first signed up for an Exploros account
      if ($location.search().error == 'signin_no_account') {
        $scope.error.loginValid = false;
        $scope.error.text = errors.signin_no_account;
        $scope.googleRoute += '&prompt=select_account';
      }
      // Show login error if a user tries to sign in with Google and denies authorization to the app
      if ($location.search().error == 'signin_google_denied') {
        $scope.error.loginValid = false;
        $scope.error.text = errors.signin_google_denied;
        $scope.googleRoute += '&prompt=select_account';
      }
      // Show login error if a user tries to sign in with a Google email address and password instead of using the Sign In With Google button
      if ($location.search().error == 'signin_email_google_user') {
        $scope.error.loginValid = false;
        $scope.error.text = errors.signin_email_google_user;
        $scope.googleRoute += '&prompt=select_account';
      }
      // Show login error if a user tries to sign in with a Google email address and password instead of using the Sign In With Google button
      if ($location.search().error == 'signin_google_exists') {
        $scope.error.loginValid = false;
        $scope.error.text = errors.signin_google_exists;
        $scope.googleRoute += '&prompt=select_account';
      }
      // Show generic signup error
      if ($location.search().error == 'signup') {
        $scope.error.loginValid = false;
        $scope.error.text = errors.signup;
      }
      if ($location.search().error == 'bad_domain') {
        $scope.error.loginValid = false;
        $scope.error.text = errors.bad_domain;
      }
    }

    var credentialsChangeCounter = 0;

    $scope.credentialsChanged = function () {
      credentialsChangeCounter = credentialsChangeCounter + 1;
      if (credentialsChangeCounter < 2) {
        // display an error even if browser adds login credentials and changes state
        return;
      }
      $location.search('error', null);
      $scope.error.nameValid = true;
      $scope.error.loginValid = true;
      $scope.error.text = undefined;
    };

    $scope.connectToClass = function () {
      $location.path('/joinAClass').replace();
    };

    // Is called when the user clicks the login button
    $scope.login = function () {
      return loginService.login($scope.user.username, $scope.user.password).then(function (data) {
        if (data.error) {
          $log.error("login factory expected error:", data.error);
        }
        // if the return object is an error object then display the error information in the login dialog
        if (typeof data.error == 'object') {
          $log.debug("Invalid login attempt");

          // If this is an invalid user then update the UI
          if (data.error.description == "unknown user") {
            $scope.error.nameValid = false;
            $scope.error.text = errors.signin_no_user;
          } else if (data.error.description == "invalid password") {
            $scope.error.loginValid = false;
            $scope.error.text = errors.signin_wrong_password;
          } else {
            $scope.error.loginValid = false;
            $scope.error.text = data.error.description;
          }

          $location.path('/login').replace();
        }
      })
        .catch(function (error) {
          $log.error("login error:", error);
          return loginService.invalidate();
        });
    };

    $scope.loginWithGoogle = function () {
      $window.location.href = $scope.googleRoute;
    };

    $scope.loginWithGG4L = function () {
      $window.location.href = $scope.gg4lRoute;
    };

    $scope.loginWithClever = function () {
      $window.location.href = $scope.cleverRoute;
    };

    $scope.loginWithClasslink = function () {
      $window.location.href = $scope.classlinkRoute;
    };
  }

  module.component('xpLogin', {
    template: require('./login.jade'),
    controller: controller
  });
})();
