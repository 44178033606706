'use strict';
(function(){

  var module = angular.module('client.directives');

  module.directive('xpWhiteboard', ['$location',
   function($location) {

     function link(scope, element, attrs)
     {
       scope.onShowStudentResponses = function() {
         if (scope.onStudentResponses) {
           scope.onStudentResponses({show: scope.showStudentResponses});
         }
       };

       scope.onSynchNavigation = function() {
         if (scope.onNavigationSync) {
           scope.onNavigationSync({sync: scope.synchNavigation});
         }
       };
     }

     return {
       replace: true,
       restrict: 'E',
       transclude: true,
       template: require('./projectorView.jade'),
       link: link,
       scope: {
         projectorView: '=',
         controllerView: '=',
         showStudentResponses: '=',
         synchNavigation: '=',
         onStudentResponses: '&',
         onNavigationSync: '&'
       }
     };
   }]);
})();
