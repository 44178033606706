'use strict';
/*jshint -W099*/
/* Controllers */
// 'ui.bootstrap'

clientControllers.controller('ActiveExperienceCtrl', ['$http', '$scope', '$rootScope', '$window',
  '$route', '$routeParams', '$interval', '$q', 'ActivityStateFactory', '$location', '$timeout',
  'User', 'JSONStringUtility', 'ElementRealtimeService', 'StartActivityFactory', '$log',
  'ActiveExperienceService', 'TeacherControlledEvent', 'ElementsRestService', 'ActiveExperience', 'ModalService', '$anchorScroll',
  'PermissionConsts', 'GoogleDrive', 'VideoStreamingService', 'StudentStreamingService', 'TeacherStreamingService', 'xpAlert',
  'ActiveMode', 'elementStorage', 'tourService', 'EventAuditAnalyticsService', 'CorrectResponseId', 'WhiteboardService', 'WhiteboardRealtimeService',
  function ($http, $scope, $rootScope, $window, $route, $routeParams, $interval, $q,
            ActivityStateFactory, $location, $timeout, User, JSONStringUtility,
            ElementRealtimeService, StartActivityFactory, $log,
            ActiveExperienceService, TeacherControlledEvent, ElementsRestService, ActiveExperience, ModalService, $anchorScroll,
            PermissionConsts, GoogleDrive, VideoStreamingService, StudentStreamingService, TeacherStreamingService, xpAlert,
            ActiveMode, elementStorage, tourService, EventAuditAnalyticsService, CorrectResponseId, WhiteboardService, WhiteboardRealtimeService) {

    // Get the experience id from the URL
    var experienceId = Number($routeParams.id);

    // Grab the default scene id passed on URL
    $scope.defaultSceneId = parseInt($routeParams.sceneid, 10);

    $scope.experience = null;
    $scope.standardsByScene = [];

    ElementsRestService.enableExperienceCache(experienceId);

    $scope.$on('$destroy', function () {
      ElementsRestService.disableExperienceCache(experienceId);
    });

    function elementTypeIsInteractive(elementType) {
      return elementType == "choice" || elementType == "drag_drop_text" || elementType == "drag_drop_image" ||
        elementType == "fill_in_the_blank" || elementType == "poll" || elementType == "word_cloud" || elementType == "wall" ||
        elementType == "table" || elementType == "draw" || elementType == "graphic_organizer" || elementType == "inline_choice" ||
        elementType == "hot_spot" || elementType == "hot_text" || elementType == "multi_part";
    }

    function hasUnsubmittedResponses() {
      var hasUnsubmitted = false;
      if ($scope.currentScene) {
        $scope.currentScene.clusters.forEach(function (cluster) {
          cluster.elements.forEach(function (element) {
            if (elementTypeIsInteractive(element.type) && elementStorage.getState($scope.userId, $scope.experience.id, element.id)) {
              hasUnsubmitted = true;
            }
          });
        });
      }
      return hasUnsubmitted;
    }

    function stayOnScene(checkTouring) {
      if (checkTouring && !tourService.canNavigate('#page_navigation')) {
        return true;
      }
      if (!$scope.stayOnScenePrompted && hasUnsubmittedResponses()) {
        if (!window.confirm('Some of your responses have not been submitted yet. Are you sure you want to leave this scene without submitting them?')) {
          return true;
        } else {
          $scope.stayOnScenePrompted = true;
        }
      }
      return false;
    }

    $scope.$on("$locationChangeStart", function (event) {
      if (stayOnScene()) {
        event.preventDefault();
      }
      $scope.stayOnScenePrompted = false;
    });

    // $scope.$on('onfocus', function (e, args) {
    //   if ($scope.previewEnabled) {
    //     EventAuditAnalyticsService.save({
    //       url: args.url,
    //       user_id: args.user_id,
    //       experience_id: $scope.experience.id,
    //       scene_template_id: $scope.currentSceneId,
    //       event_name: "Preview Focus Start"
    //     }).$promise.then(function () {
    //       $log.info("Preview Focus Start onfocus ", JSON.stringify(args));
    //     });
    //     e.preventDefault();
    //   }
    // });

    // $scope.$on('onblur', function (e, args) {
    //   if ($scope.previewEnabled) {
    //     EventAuditAnalyticsService.save({
    //       url: args.url,
    //       user_id: args.user_id,
    //       experience_id: $scope.experience.id,
    //       scene_template_id: $scope.currentSceneId,
    //       event_name: "Preview Focus End"
    //     }).$promise.then(function () {
    //       $log.info("Preview Focus End onblur ", JSON.stringify(args));
    //     });
    //     e.preventDefault();
    //   }
    // });

    $scope.onChange = function (elementId, selection) {
      if (selection) {
        elementStorage.saveState($scope.userId, $scope.experience.id, elementId, selection);
      } else {
        elementStorage.clearState($scope.userId, $scope.experience.id, elementId);
      }
    };

    $scope.getCached = function (elementId) {
      return elementStorage.getState($scope.userId, $scope.experience.id, elementId);
    };

    // Watch for changes to the current experience.  If this is a teacher then the list of students may need to be updated
    $scope.$watch(function () {
        return ActiveExperience.currentExperience();
      },
      function (value) {
        if (value) {
          $scope.experience = value;
          if ($scope.context) {
            $scope.context.studentFacingFeedback = $scope.experience.student_facing_feedback;
          }
        }
      });

    getActiveExperienceData();

    // Preload experience data
    function getActiveExperienceData() {
      return ActiveExperience.getExperience(experienceId).then(function (experience) {
        if (!experience) {
          $log.debug("Invalid experience ID: " + experienceId);
          if (User.isCanvasUser()) {
            xpAlert.error("You were not invited to this particular learning experience &#45; it was intended for others in your Canvas Course.", true);
          } else {
            xpAlert.error("You were not invited to this particular learning experience.", true);
          }
          ActiveMode.navigateToExperiences();
          return;
        }

        if (experience.status === 'ARCHIVED' && experience.teacher && experience.teacher.canvas_user) {
          $log.debug("Archived Experience for canvas user: " + JSON.stringify(experience));
          xpAlert.error("This learning experience has ended and no one participated so it has been removed.", true);
          ActiveMode.navigateToExperiences();
          return;
        }

        $scope.experience = experience;
        $scope.guidedNavEnabled = experience.is_guided;
        $scope.eyesOnTeacherEnabled = false;
        $scope.previewEnabled = experience.preview;

        if (!experience.preview && experience.status === 'ACTIVE') {
          // Initialize streaming to current experience
          if ($scope.userIsStudent()) {
            StudentStreamingService.initStudentStreaming($scope.experience.id);
          } else {
            TeacherStreamingService.initTeacherStreaming($scope.experience.id);
          }

          VideoStreamingService.setCurrentScene($routeParams.sceneid);
          $scope.shareVideo = TeacherStreamingService.isTeacherStreamingVideo();
        }

        // Initialize of Google drive for use in elements
        if (ActiveExperience.hasPermission(experienceId, PermissionConsts.integration_curriculum_google_drive) && User.isGoogleUser()) {
          GoogleDrive.init();
        }

        if (ActiveExperience.hasPermission(experienceId, PermissionConsts.ui_curriculum_show_experience_card_standards)) {
          ActiveExperience.getStandardsByScene(experienceId).then(function (standardsByScene) {
            $scope.standardsByScene = standardsByScene;
          });
        }

        $scope.DISPLAY_MODE = Object.freeze({
          kExperienceView: {"id": 0, "title": "Experience", "answersOnly": false},
          kStudentWorkView: {"id": 1, "title": "Student Work", "answersOnly": true}
        });

        $scope.FILTERS = Object.freeze({
          kSummary: {"uid": 0, "displayName": "Summary", "report": 1},
          kEachStudent: {"uid": 0, "displayName": "Each Student", "report": 2}
        });

        function XPUser(uid, firstName, lastName, email, small_group, reading_level, google_user) {
          this.uid = uid;
          this.firstName = firstName;
          this.lastName = lastName;
          this.email = email;
          this.displayName = firstName && firstName.length && lastName && lastName.length ? firstName + " " + lastName : email;
          this.small_group = small_group;
          this.reading_level = reading_level;
          this.google_user = google_user;
        }

        function Clazz(classId, teacher, coTeachers, students, smallGroups) {
          var self = this;
          this.classId = classId;
          if (teacher) {
            this.teacher = new XPUser(
              teacher.id, teacher.first_name, teacher.last_name, teacher.email, 0, teacher.google_user);
          } else {
            this.teacher = new XPUser(
              -1, '', '', '', false, 0, false);
          }

          this.teachers = [];
          if (coTeachers) {
            coTeachers.forEach(function (coTeacher) {
              self.teachers.push(new XPUser(
                coTeacher.id,
                coTeacher.first_name,
                coTeacher.last_name,
                null,
                null,
                null,
                false));
            });
          }

          this.students = [];

          if (students) {
            students.forEach(function (student) {
              self.students.push(new XPUser(
                student.user_id,
                student.first_name,
                student.last_name,
                student.email,
                student.small_group,
                student.reading_level,
                student.google_user));
            });
          }

          this.userWithId = function (userId) {
            if (this.teacher && this.teacher.uid === userId) {
              return this.teacher;
            }

            var usr = this.students.find(function (student) {
              return userId === student.uid;
            });

            if (!usr) {
              usr = this.teachers.find(function (teacher) {
                return userId === teacher.uid;
              });
            }

            return usr;
          };

          this.smallGroups = smallGroups;
        }

        function Context(experienceId, classId, userId, groupId, userIsStudent, userIsTeacher, teacher, coTeachers, students,
                         status, showAnswersOnly, filteredUser, smallGroups, studentFacingFeedback, preview, review) {
          var self = this;

          this.experienceId = experienceId;
          this.userId = userId;
          this.groupId = groupId;
          this.userIsStudent = userIsStudent;
          this.userIsTeacher = userIsTeacher;
          this.clazz = new Clazz(classId, teacher, coTeachers, students, smallGroups);
          this.status = status;
          this.showAnswersOnly = showAnswersOnly === undefined ? false : showAnswersOnly;
          this.filteredUser = filteredUser === undefined ? $scope.FILTERS.kSummary : filteredUser;
          this.studentFacingFeedback = studentFacingFeedback;
          this.isPreview = preview;
          this.isReview = review;

          this.getMetricExperienceTimeOnScreenData = function getMetricExperienceTimeOnScreenData(experience) {
            if (!experience || !experience.id || experience.preview) {
              return false;
            }
            return {
              user_id: $scope.userId,
              experience_id: experience.id,
              experience_template_id: experience.template_id,
              class_id: experience.class_id,
              experience_status: experience.status
            };
          };

          this.getUserIsTeacher = function (userId) {
            if (userId == self.clazz.teacher.uid) {
              return true;
            }
            return self.clazz.teachers.find(function (teacher) {
              return userId === teacher.uid;
            });
          };

          this.getTeacher = function () {
            return self.clazz.teacher.uid;
          };

          this.getUserDisplayName = function (userId) {
            var user = self.clazz.userWithId(userId);
            if (user === null || angular.isUndefined(user)) {
              return undefined;
            }
            return user.displayName;
          };

          this.getUserEmail = function (userId) {
            var user = self.clazz.userWithId(userId);
            if (user) {
              return self.clazz.userWithId(userId).email;
            } else {
              return "";
            }
          };

          this.getViewingInactiveExperience = function () {
            return self.status == "INACTIVE";
          };

          this.getSelectedStudents = function () {
            return self.clazz.students;
          };

          this.getUserGroup = function (userId) {
            var user = self.clazz.userWithId(userId);
            if (user) {
              return user.small_group;
            } else {
              return 0;
            }
          };

          this.getStudentFacingFeedback = function () {
            return self.studentFacingFeedback && !self.userIsTeacher();
          };

          this.getPostingGroupId = function (isUsingSmallGroups) {
            if (isUsingSmallGroups && self.clazz.smallGroups > 0) {
              if (self.getUserIsTeacher(self.userId)) {
                return self.clazz.smallGroups + 1;  // Default teacher to N + 1 group
              } else {
                return groupId;
              }
            } else {
              return 0;
            }
          };

          this.getIsTeacherSmallGroupPost = function (grpId) {
            if (self.userIsTeacher() && self.clazz.smallGroups > 0 && grpId == self.clazz.smallGroups + 1) {
              return true;
            } else {
              return false;
            }
          };

          this.getSelectedRespondentId = function (isUsingSmallGroups, userId) {
            if (!self.userIsTeacher()) {
              return userId;
            } else if (isUsingSmallGroups) {
              return self.clazz.smallGroups + 1;
            } else if (userId == CorrectResponseId.CORRECTANSWERS) {
              return this.userId;
            } else {
              return userId;
            }
          };
        }

        $scope.scenes = [];
        $scope.sceneIndices = [];
        $scope.previousSceneIndices = [];
        $scope.scenesPerGroup = 5;
        $scope.sceneNavRendered = false;
        $scope.context = new Context();
        $scope.pastExperienceView = $scope.DISPLAY_MODE.kExperienceView;
        $scope.pageElementsLoaded = false;
        $scope.activityDataLoaded = false;
        $scope.numPageElementsResponded = 0;
        $scope.sceneElementCounts = [];
        $scope.classLevel = "default";
        $scope.resource_activity_suffix = '-blue';
        $scope.active_page = "activity";
        $scope.lastSceneIndex = ActiveExperienceService.getLastScene(User.getId(), experienceId);
        $scope.lockedGateElementId = 0;

        $scope.packtype = $scope.userIsTeacher() ? "teacher" : "student";

        // Get the cached scene count for this experience
        $scope.sceneCount = ActiveExperienceService.getSceneCount(User.getId(), experienceId);
        for (var sceneIndex = 0; sceneIndex < $scope.sceneCount; ++sceneIndex) {
          $scope.sceneIndices.push(sceneIndex);
        }

        // keep the current user id
        $scope.userId = User.getId();

        // Default the to scene specified in the URL
        function getScene() {
          var deferred = $q.defer();
          var sceneId = $routeParams.sceneid ? Number($routeParams.sceneid) : 0;
          // If the scene ID is invalid then check the cookies to see what the last scene this user was on
          if (!sceneId || Number(sceneId) === 0) {
            if (!$scope.guidedNavEnabled || ($scope.guidedNavEnabled && !$scope.userIsStudent())) {
              // Get the value from the cookie
              ActiveExperienceService.getScene($scope.userId, experienceId).then(function (lastScene) {
                // if still invalid then just default to the first scene
                if (Number(lastScene) === 0) {
                  lastScene = 1;
                }
                deferred.resolve(lastScene);
              });
            } else {
              // if still invalid then just default to the first scene
              if (Number(sceneId) === 0) {
                sceneId = 1;
              }
              deferred.resolve(sceneId);
            }
          } else {
            // if still invalid then just default to the first scene
            if (Number(sceneId) === 0) {
              sceneId = 1;
            }
            deferred.resolve(sceneId);
          }

          return deferred.promise;
        }

        $scope.explorosImage = "resources/Icon-50.png";

        // Handle any URL parameters passed in for printing
        $scope.reportingStudentId = $routeParams.student;
        if ($scope.reportingStudentId !== undefined) {
          // Check for the report parameter
          var reportType = $routeParams.report;

          // if a report type then set it
          if (reportType) {
            // if the student ID is a real student then set the filtered user
            if (reportType == 1) {
              $scope.context.filteredUser = $scope.FILTERS.kSummary;
            } else if (reportType == 2) {
              $scope.context.filteredUser = $scope.FILTERS.kEachStudent;
            }

            // This indicates printing so must be set to student work view
            $scope.pastExperienceView = $scope.DISPLAY_MODE.kStudentWorkView;
          } else {
            $scope.pastExperienceView = $scope.DISPLAY_MODE.kExperienceView;
          }
        }

        // Error for scene selection
        $scope.xpErrorHeader = {};
        $scope.validationHeaderFailed = function () {
          // If error message holder has data
          if ($scope.xpErrorHeader.details !== null && $scope.xpErrorHeader.details !== undefined) {
            return $scope.xpErrorHeader.details.length > 0 ? true : false;
          }
          return false;
        };

        $scope.isPastExperience = function () {
          return $scope.experience.status === 'INACTIVE';
        };

        $scope.toggleView = function (id) {
          if (id === 0) {
            $scope.pastExperienceView = $scope.DISPLAY_MODE.kExperienceView;
          } else if (id === 1) {
            $scope.pastExperienceView = $scope.DISPLAY_MODE.kStudentWorkView;

            // If this is a student then the only choice for student work is just for them
            if ($scope.context.userIsStudent()) {
              $scope.context.filteredUser = $scope.context.clazz.userWithId($scope.userId);
            }
          }

          // Assign this into the context
          $scope.context.showAnswersOnly = $scope.pastExperienceView.answersOnly;
        };

        $scope.isShowingStudentLog = function () {
          return $scope.active_page == "activity" && $scope.isPastExperience() && $scope.pastExperienceView.id === 1;
        };

        // There is the active and the past experience, set variable depending on routeParams
        $scope.isInactive = false;
        if ($routeParams.isInactive !== null && $routeParams.isInactive !== undefined) {
          var bValue = ($routeParams.isInactive.toLowerCase() === "true");
          $scope.isInactive = bValue;
          // Need to broadcast so other ActiveDashboardCtrl  know if this is active/inactive page
          var InactivemessageArgs = {isInactive: $scope.isInactive};
          $rootScope.$broadcast('XP_HINT_EXPERIENCE_STATE', InactivemessageArgs);
        }

        $scope.initExperience = function () {
          // This updates the student record on server indicating the student has at least opened and viewed this experience
          StartActivityFactory.get({},
            {'id': experienceId, 'user_id': $scope.userId}, function (ret) {
              $log.debug("StartActivityFactory for experience: " + experienceId + " for user " + $scope.userId);
            });

          function get_current_student_rec(students) {
            if (!students) {
              return null;
            }

            for (var i = students.length - 1; i >= 0; i--) {
              if (students[i].user_id == $scope.userId) {
                return students[i];
              }
            }

            return null;
          }

          // if this is the teacher, then send message that the scene is changing
          if ($scope.guidedNavEnabled && $scope.userIsTeacher() && $scope.experience.status !== 'INACTIVE') {
            ActivityStateFactory.post({}, {
              id: experienceId,
              user_id: $scope.userId,
              current_scene_id: Number($scope.currentSceneId)
            }).$promise
              .then(function (state) {
                $log.debug("Updating scene number: " + JSON.stringify(state));
              })
              .catch(function (err) {
                $log.error("error in activity state post:", err);
              });

            TeacherControlledEvent.post({}, {
              'id': $scope.experience.id,
              'sceneId': Number($scope.currentSceneId)
            });
          }

          // This grabs the current student and determines what group they are in
          var teacherId = $scope.experience.teacher.id;
          var current_student = get_current_student_rec($scope.experience.students);

          if ($scope.guidedNavEnabled && $scope.userIsStudent() && $scope.experience.status !== 'INACTIVE') {
            ActivityStateFactory.get({}, {'id': experienceId, 'user_id': teacherId}).$promise
              .then(function (state) {
                if (state.hasOwnProperty('current_scene_id')) {
                  if ($scope.currentSceneId != state.current_scene_id) {
                    setScene(state.current_scene_id - 1);
                  }
                }
              })
              .catch(function (err) {
                $log.error("error in activity state get:", err);
              });
          }

          var firstLockedGateId = 0;
          var gateLockBypassed = false;

          $scope.experience.activity.content.scenes.forEach(
            function (scene) {
              scene.settings = JSONStringUtility.parse(scene.settings) || {};
              $scope.sceneElementCounts.push(0);
              scene.clusters.forEach(
                function (cluster) {
                  cluster.elements.forEach(
                    function (element) {
                      $scope.sceneElementCounts[$scope.sceneElementCounts.length - 1]++;
                      element.config = JSONStringUtility.parse(element.config);

                      function isLockedGate() {
                        // if this element is a gate then see if it is unlocked
                        if (element.type == "HTMLteacher_gate" || "teacher_gate" == element.type) {
                          return (element.progress === 0 && $scope.experience.status != "INACTIVE");
                        }

                        return false;
                      }

                      // If guided nav is turned on and we're looping through the current scene
                      if ($scope.guidedNavEnabled) {
                        if (!gateLockBypassed && scene.id ===
                          $scope.experience.activity.content.scenes[$scope.currentSceneIndex].id) {
                          if ($scope.experience.activity.content.scenes[$scope.currentSceneIndex].clusters[0].elements[0].id <
                            element.id) {
                            if (isLockedGate()) {
                              firstLockedGateId = element.id;
                              gateLockBypassed = true;
                            }
                          }
                        }
                      } else {
                        if (isLockedGate() && firstLockedGateId === 0) {
                          firstLockedGateId = element.id;
                        }
                      }
                    }
                  );
                }
              );
            }
          );

          $scope.lockedGateElementId = firstLockedGateId;

          $scope.scenes = $scope.experience.activity.content.scenes;
          $scope.currentScene = $scope.scenes[$scope.currentSceneIndex];
          $scope.sceneCount = ActiveExperienceService.setSceneCount($scope.userId, experienceId, $scope.scenes.length);
          $scope.sceneIndices = [];
          for (var sceneIndex = 0; sceneIndex < $scope.scenes.length; ++sceneIndex) {
            $scope.sceneIndices.push(sceneIndex);
          }

          $scope.context = new Context(experience.id, experience.class_id, $scope.userId, current_student ? current_student.small_group : 0,
            $scope.userIsStudent, $scope.userIsTeacher, $scope.experience.teacher, $scope.experience.teachers, $scope.experience.students, experience.status,
            $scope.pastExperienceView.answersOnly, $scope.context.filteredUser, experience.small_groups,
            $scope.studentFacingFeedback, experience.preview, experience.review);

          // if there was a student passed in then this view should just show their data
          if ($scope.reportingStudentId) {
            $scope.showStudentResults($scope.context.clazz.userWithId($scope.reportingStudentId));
          }

          // Initialize the context since we are now loaded
          if ($scope.userIsTeacher() || $scope.isPastExperience()) {
            $scope.lastSceneIndex = $scope.scenes.length - 1;
            $timeout(function () {
              $scope.$apply();
            });
            ActiveExperienceService.setLastScene($scope.userId, experienceId, $scope.lastSceneIndex);
          }

          // ============================================================================================
          // Eyes on Teacher
          // ============================================================================================

          $scope.eyesOnTeacherActive = function () {
            return $scope.eyesOnTeacherEnabled;
          };

          $scope.toggleEyesOnTeacher = function (force) {
            $scope.eyesOnTeacherEnabled = (typeof force !== 'undefined') ? force : !$scope.eyesOnTeacherActive();

            var modalId = 'eyes-on-teacher';
            var collectionStyle = $scope.style ? ('collection-' + $scope.style) : '';

            if ($scope.userIsStudent() && $scope.eyesOnTeacherActive() && ModalService.active().$id !== modalId) {
              ModalService.show({
                id: modalId,
                template: require('../../views/partials/modals/eyesOnTeacherModal.jade'),
                placement: 'center',
                style: collectionStyle,
                animation: 'am-fade-and-scale',
                backdrop: 'static',
                backdropAnimation: 'eyes-on-teacher ' + collectionStyle,
                keyboard: false
              });
            }
          };

          if ($scope.guidedNavEnabled) {
            if ($scope.experience.status === 'INACTIVE') {
              if ($scope.currentScene.settings &&
                $scope.currentScene.settings.eyesOnTeacherEnabled &&
                $scope.userIsStudent) {
                notifyOnPageElementsLoaded();
              }
            } else {
              ActiveExperience.getStyle(experienceId).then(function (style) {
                if (style && style.project_style) {
                  $scope.style = style.project_style;
                }
                // Enable Eyes on Teacher on scene load if it's set in the scene settings
                if ($scope.currentScene.settings && $scope.currentScene.settings.eyesOnTeacherEnabled) {
                  $scope.toggleEyesOnTeacher(true);
                } else {
                  $scope.toggleEyesOnTeacher(false);
                }
              })
              .catch(function (err) {
                $log.error("error in get style:", err);
              });
            }
          } else if ($scope.experience.project_id) {
            ActiveExperience.getStyle(experienceId).then(function (style) {
              if (style && style.project_style) {
                $scope.style = style.project_style;
              }
            })
            .catch(function (err) {
              $log.error("error in get style:", err);
            });
          }

          if ($scope.eyesOnTeacherEnabled && $scope.userIsStudent()) {
            return;
          }

          notifyOnActivityDataLoaded();
          initializePageNavigation(experienceId);
          $scope.renderSceneNav();

        };

        getScene().then(function (sceneId) {
          $scope.currentSceneId = sceneId;
          $scope.currentSceneIndex = $scope.currentSceneId - 1;

          $scope.renderSceneNav();

          // Get the active experience information
          $scope.initExperience();
        })
          .catch(function (error) {
            $log.error("error in get scene:", error);
            $scope.currentSceneId = 0;
          });

        // Listen for the elements being updated
        $scope.$on('exportPropertiesUpdated', function (event, args) {
          if (args.readyToDisplay) {
            ++$scope.numPageElementsResponded;
            if ($scope.numPageElementsResponded >= $scope.sceneElementCounts[$scope.currentSceneIndex]) {
              $timeout(notifyOnPageElementsLoaded);
            }
          }
        });

        function notifyOnPageElementsLoaded() {
          // Prevent the event to get broadcast more than once.
          if (!$scope.pageElementsLoaded) {
            $scope.pageElementsLoaded = true;
            $scope.$broadcast('pageElementsLoaded');
            $timeout(function () {
              $anchorScroll();
            }, 1000);
          }
        }

        function notifyOnActivityDataLoaded() {
          // Prevent the event from getting broadcast more than once.
          if (!$scope.activityDataLoaded) {
            $scope.activityDataLoaded = true;
          }
        }

        // Catch error timer that makes sure activity gets displayed even when error occurs
        function createLoaderTimeout() {
          $timeout(notifyOnPageElementsLoaded, 5000);
        }

        createLoaderTimeout();

        function initializePageNavigation(experienceId) {
          // If this is a student then the only choice for student work is just for them
          if ($scope.reportingStudentId !== undefined && $scope.reportingStudentId > 0) {
            $scope.context.filteredUser = $scope.context.clazz.userWithId($scope.reportingStudentId);
          }

          // if this is a student then get their current activity state
          if ($scope.userIsStudent() && !$scope.isPastExperience()) {
            ActivityStateFactory.get({}, {'id': experienceId, 'user_id': $scope.userId}).$promise
              .then(function (state) {
                if (state !== null && state.max_scene_id !== null) {
                  // Find the index of the scene with this scene id
                  var index = 0;
                  $scope.scenes.forEach(function (scene) {
                    if (scene.id == state.max_scene_id) {
                      // You should not need to call this function but for some reason the UI is not refreshing.
                      // In order for the next scene indicators to become visible, we need to force angular to apply any changes here.
                      $scope.lastSceneIndex = index;
                      $timeout(function () {
                        $scope.$apply();
                      });
                      ActiveExperienceService.setLastScene($scope.userId, experienceId, index);
                    }

                    // increment the index
                    index++;
                  });
                }
              })
              .catch(function (err) {
                $log.error("error in activity state for user:", err);
              });
          }
        }

        WhiteboardRealtimeService.on(WhiteboardRealtimeService.EVENTS.WhiteboardEvent,
          updateWhiteboardNotificationHandler);

        function updateWhiteboardNotificationHandler(e) {
          var msg = e.detail;
          var data = msg.record;
          var event = data.event;

          if (!ActiveExperience.currentExperience().whiteboardProjectorView) {
            return;
          }

          if (!$scope.experience || !$scope.experience.id || data.id != $scope.experience.id) {
            return;
          }

          if (event == 'scene') {
            setScene(data.value);
          }
        }

        function setScene(selectedSceneIndex) {
          $scope.currentSceneIndex = selectedSceneIndex;

          if (!$scope.experience.id) {
            return;
          }

          // Save a cookie indicating this is the last scene the user visited
          ActiveExperienceService.setScene($scope.userId, $scope.experience.id, selectedSceneIndex + 1);

          // If this is a past experience and is being filtered by a particular student
          var filteredStudent = {};
          if ($scope.isPastExperience() && $scope.context.filteredUser && $scope.context.filteredUser.uid > 0) {
            filteredStudent = {student: $scope.context.filteredUser.uid};
          }

          // Set the path so the URL contains the correct info
          $location.path('/experience/' + $scope.experience.id + '/activity/scene/' + (Number(selectedSceneIndex) + 1)).search(filteredStudent);

          // EventAuditAnalyticsService.save({
          //   event_name: "Enter Scene",
          //   user_id: $scope.userId,
          //   experience_id: $scope.experience.id,
          //   url: $window.location.href,
          //   scene_template_id: $scope.currentSceneId
          // }).$promise.then(function () {
          //   $log.info("experience.active setScene");
          // });

          // By changing the index, angular is going to reload and get the new data
          $scope.pageElementsLoaded = false;
          createLoaderTimeout();
        }

        $scope.$on('TeacherControlledEvent', function (e, args) {
          var expId = Number(args.id);
          var sceneId = Number(args.name);
          var event = args.event;

          // Return if this is not a change event
          if (event !== 'change') {
            return;
          }

          // Return if the message is not intended for this experience
          if (expId != $scope.experience.id) {
            return;
          }

          // Only change scenes if the scene id is a different number than the current scene
          // (prevents reloading of the same scene if the teacher refreshes the page, etc)
          if (sceneId != $scope.currentSceneId) {
            setScene(sceneId - 1);
          }
        });

        // Handle element update notifications
        ElementRealtimeService.on(ElementRealtimeService.EVENTS.XPActivityStateChangedNotification,
          activityChangedNotificationHandler);

        $scope.$on('$destroy', function () {
          ElementRealtimeService.removeListener(ElementRealtimeService.EVENTS.XPActivityStateChangedNotification,
            activityChangedNotificationHandler);
        });

        function activityChangedNotificationHandler(e) {
          var state = e.detail;
          $log.debug("Received activity update: " + JSON.stringify(state));

          // Only change if this is a student user
          if ($scope.userIsStudent()) {
            // make sure this is a valid change notification for this user on this experience
            if (state !== null && state.record !== null &&
              state.record.experience_id == $scope.context.experienceId &&
              state.record.user_id == $scope.context.userId) {
              // Find the index of the scene with this scene id
              var index = 0;

              $scope.scenes.forEach(function (scene) {
                // if the scene id matches then this is either the scene the teacher unlocked or unlocked based on requirements.
                // This means we need to allow access to the next scene
                if (scene.id == state.record.max_scene_id) {
                  // You should not need to call this function but for some reason the UI is not refreshing.
                  // In order for the next scene indicators to become visible, we need to force angular to apply any changes here.
                  $scope.lastSceneIndex = index;
                  $timeout(function () {
                    $scope.$apply();
                  });
                  // Keep this information for navigation purposes when switching scenes
                  ActiveExperienceService.setLastScene($scope.userId, experienceId, index);
                }

                // increment the index
                index++;
              });
            }
          }
        }

        ElementRealtimeService.on(ElementRealtimeService.EVENTS.XPElementStateChangedNotification, elementChangedNotificationHandler);
        $scope.$on('$destroy', function () {
          ElementRealtimeService.removeListener(ElementRealtimeService.EVENTS.XPElementStateChangedNotification, elementChangedNotificationHandler);
        });

        function extractAndParserUserData(state) {
          if (!state) {
            return {};
          }
          // for gates
          if (!state.user_data) {
            return {
              unlocked: false
            };
          }
          if (state.user_data === 'unlocked') {
            return {
              unlocked: true
            };
          }
          try {
            return JSON.parse(state.user_data) || {};
          }
          catch (e) {
            $log.error("failed to parse userData from state:", state.user_data);
            return {};
          }
        }

        function elementChangedNotificationHandler(e) {
          var message = e.detail;
          var state = message.record;

          $log.debug("Received element update: " + JSON.stringify(message));

          // if this is an "unlocked" message then make sure to update the last unlock id
          var userData = extractAndParserUserData(state);

          if (userData.unlocked) {
            var nextLockedGateId = 0;

            $log.debug("unlocking gate");

            // Loop through the scene information
            $scope.scenes.forEach(function (scene) {
              scene.clusters.forEach(function (cluster) {
                cluster.elements.forEach(function (element) {
                  // if this element is a gate
                  if (element.type == "HTMLteacher_gate" || "teacher_gate" == element.type) {
                    // if this is the item that is changing
                    if (element.id <= state.element_id) {
                      element.progress = 1;
                    }

                    // if this is a locked element and it is the next locked one then save the ID
                    if (element.progress === 0 && nextLockedGateId === 0) {
                      nextLockedGateId = element.id;
                    }
                  }
                });
              });
            });

            // Assign the next locked gate
            $scope.lockedGateElementId = nextLockedGateId;
            $timeout(function () {
              $scope.$apply();
            });
          }
        }

        // Extract the experience Id from the URL
        $scope.timeOnScreenInterval = 30000;	// 30 second interval
        $scope.start = new Date();

        function notifyServerOfStateChange() {
          if ($scope.currentSceneIndex >= $scope.scenes.length) {
            return;
          }

          var end = new Date();
          var scene = $scope.currentScene;

          function getScreenElements() {
            var deferred = $q.defer();

            var elements = [];

            scene.clusters.forEach(function (cluster) {
              cluster.elements.forEach(function (element) {
                elements.push(element.id);
              });
            });

            deferred.resolve(elements);

            return deferred.promise;
          }

          getScreenElements();

          $scope.start = end;
        }

        // Create a timer that updates the state to the server periodically
        var timeOnScreen;
        timeOnScreen = $interval(notifyServerOfStateChange, $scope.timeOnScreenInterval);

        $scope.$on('$destroy', function () {
          // Make sure that the interval is destroyed too
          $interval.cancel(timeOnScreen);
        });

        // $scope.$on('onfocus', function () {
        //   EventAuditAnalyticsService.save({
        //     event_name: "Scene Browser Focus Start",
        //     user_id: $scope.userId,
        //     experience_id: $scope.experience.id,
        //     url: $window.location.href,
        //     scene_template_id: $scope.currentSceneId
        //   }).$promise.then(function () {
        //     $log.info("experience.active onfocus");
        //   });
        // });

        // $scope.$on('onblur', function () {
        //   EventAuditAnalyticsService.save({
        //     event_name: "Scene Browser Focus End",
        //     user_id: $scope.userId,
        //     url: $window.location.href,
        //     experience_id: $scope.experience.id,
        //     scene_template_id: $scope.currentSceneId
        //   }).$promise.then(function () {
        //     $log.info("experience.active onblur");
        //   });
        // });

        $scope.showStudentResults = function (selectedStudent) {
          $scope.context.filteredUser = selectedStudent;
          $scope.filteredStudent = selectedStudent;
        };

        $scope.showStandards = function () {
          return $scope.standardsByScene && $scope.standardsByScene[$scope.currentSceneIndex] && $scope.standardsByScene[$scope.currentSceneIndex].length > 0 &&
            ActiveExperience.hasPermission(experienceId, PermissionConsts.ui_curriculum_show_experience_card_standards);
        };

        $scope.onShowStandards = function () {
          var config = {
            title: 'Assign Experience',
            template: require('../../views/partials/modals/sceneStandards.jade'),
            data: {
              standards: $scope.standardsByScene[$scope.currentSceneIndex] || [],
              standard_set_label: $scope.experience.standard_set_label,
              standard_set_id: $scope.experience.standard_set_id,
              sceneId: $scope.currentSceneIndex + 1,
              template_id: $scope.experience.template_id,
              permissions: $scope.showStandards() ? [PermissionConsts.ui_curriculum_show_experience_card_standards] : []
            }
          };
          ModalService.show(config);
        };

        $scope.printStudentExperience = function () {
          var reportId = $scope.context.filteredUser.report !== undefined ? "&report=" + $scope.context.filteredUser.report : "";
          $window.open("/printstudent?experience=" + experienceId + "&student=" + $scope.context.filteredUser.uid + reportId);
        };

        $scope.canShowNextScene = function () {
          return !stayOnScene(true);
        };

        // ============================================================================================
        // User has selected the Scene Page Buttons
        // ============================================================================================
        $scope.showCurrentScene = function (selectedSceneIndex) {
          // Angular will sometimes trigger an ng-change event on the UI before it is full loaded causing
          // the scene to jump incorrectly particularly when previewing from an external URL
          // For now this is just in place for preview so it doesn't impact anyhere else (since they seem to be working ok)
          if (!$scope.pageElementsLoaded && $scope.previewEnabled) {
            return;
          }

          $scope.currentSceneIndex = selectedSceneIndex;
          $scope.currentSceneId = selectedSceneIndex + 1;

          if (ActiveExperience.currentExperience().whiteboardControllerView) {
            WhiteboardService.setProjectorScene($scope.experience.id, $scope.currentSceneIndex);
          }

          // Teacher can see with restrictions, Student is restricted by what teacher has setup.
          // this function may be called before context is fully initialized
          if ($scope.userIsTeacher() ||
            ($scope.userIsStudent() && ($scope.lastSceneIndex === undefined || selectedSceneIndex <= $scope.lastSceneIndex))) {
            setScene(selectedSceneIndex);
          } else {
            $scope.xpErrorHeader.details = 'In order to continue to the next scene, the teacher needs to give you permission to proceed.';

          }
        };

        // ============================================================================================
        // During initialization, show first page
        // ============================================================================================
        $scope.elementRealtimeService = ElementRealtimeService;

        // ============================================================================================
        // Scene Nav
        // ============================================================================================

        $scope.renderSceneNav = function () {
          if ($scope.sceneIndices.length > 0 && !$scope.sceneNavRendered) {
            $scope.sceneNavRendered = true;
            $scope.totalScenesIndex = $scope.sceneIndices.length - 1;

            if ($scope.scenesPerGroup === $scope.sceneIndices.length) {
              $scope.firstSceneInGroup = 0;
              $scope.lastSceneInGroup = $scope.scenesPerGroup;
            } else if ($scope.currentSceneIndex <= Math.floor($scope.scenesPerGroup / 2)) {
              $scope.firstSceneInGroup = 0;
              $scope.lastSceneInGroup = $scope.scenesPerGroup - 1;
            } else if ($scope.currentSceneIndex > ($scope.totalScenesIndex - Math.floor($scope.scenesPerGroup / 2))) {
              $scope.firstSceneInGroup = ($scope.totalScenesIndex - $scope.currentSceneIndex) > 0 ? $scope.currentSceneIndex - Math.floor($scope.scenesPerGroup / 2) - ($scope.totalScenesIndex - 1 - $scope.currentSceneIndex) : ($scope.sceneIndices.length - $scope.scenesPerGroup + 1);
              $scope.lastSceneInGroup = $scope.sceneIndices.length;
              $scope.previousSceneIndices = $scope.sceneIndices.slice(0, $scope.firstSceneInGroup).reverse();
            } else {
              $scope.firstSceneInGroup = $scope.currentSceneIndex + 1 - Math.floor($scope.scenesPerGroup / 2);
              $scope.lastSceneInGroup = ($scope.currentSceneIndex + Math.floor($scope.scenesPerGroup / 2));
              $scope.previousSceneIndices = $scope.sceneIndices.slice(0, $scope.firstSceneInGroup).reverse();
            }

            $scope.currentSceneIndices = $scope.sceneIndices.slice($scope.firstSceneInGroup, $scope.lastSceneInGroup);
          }
        };

        // ============================================================================================
        // If user clicks - clear the error message if any
        // ============================================================================================
        $window.onclick = function () {
          if ($scope.xpErrorHeader.details && $scope.xpErrorHeader.details.length > 0) {
            $scope.xpErrorHeader.details = '';
          }
        };

        return experience;
      });
    }

  }]);
